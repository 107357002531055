/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import { Label, Input, Text } from '../components/ui'
import { useField } from 'formik'

const FormTextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props)
  return (
    <React.Fragment>
      <Label htmlFor={props.id || props.name}>{label}</Label>
      <Input {...field} {...props} />
      {meta.touched && meta.error ? (
        <Text variant="error">{meta.error}</Text>
      ) : null}
    </React.Fragment>
  )
}

export default FormTextInput
