/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import { Text, Textarea } from '../components/ui'
import { useField } from 'formik'

const FormTextArea = ({ label, ...props }) => {
  const [field, meta] = useField(props)
  return (
    <React.Fragment>
      <label htmlFor={props.id || props.name}>{label}</label>
      <Textarea {...field} {...props} />
      {meta.touched && meta.error ? (
        <Text variant="error">{meta.error}</Text>
      ) : null}
    </React.Fragment>
  )
}

export default FormTextArea
