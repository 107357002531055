const URL = `https://oyai0y2onf.execute-api.us-east-1.amazonaws.com/dev/form`

export const submitContact = async data => {
  try {
    const result = await fetch(URL, {
      method: 'POST',
      body: JSON.stringify(data),
    }).then(res => res.json())
    return result
  } catch (error) {
    console.error(error)
    throw error
  }
}
