import { useEffect } from 'react'
import { useFormikContext } from 'formik'

const noop = () => {}

const FormikEffect = ({ onChange = noop }) => {
  const { values } = useFormikContext()
  useEffect(() => {
    onChange({ values })
  }, [values, onChange])
  return null
}

export default FormikEffect
