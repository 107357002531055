/** @jsx jsx */
import { jsx } from 'theme-ui'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Box, Text } from '../components/ui'
import ContactForm from '../components/ContactForm'

const Contact = ({ location }) => {
  const day = location.state?.day
  const time = location.state?.time

  return (
    <Layout>
      <SEO title="Contact Us" />
      <Box>
        <Text>
          Are you ready to balance your life and get healthier? We have free
          trials available throughout week. Please let us know how we can help
          you.
        </Text>
      </Box>
      <ContactForm day={day} time={time} />
    </Layout>
  )
}

export default Contact
