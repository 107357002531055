/** @jsx jsx */
import startCase from 'lodash/startCase'
import React from 'react'
import { jsx } from 'theme-ui'
import { useFormikContext } from 'formik'
import { Flex } from './ui'
import {
  displayTime,
  demosOnDay,
  daysWithDemos,
  getDemoDates,
  getClassData,
} from '../utils/schedule'
import FormSelect from './FormSelect'

const FormRequestDemo = props => {
  const { values } = useFormikContext()
  const { demoDay } = values

  if (!values.demoClass) {
    return null
  }
  const demoTimes = demoDay ? demosOnDay(demoDay) : null
  const demoDates = demoDay ? getDemoDates(demoDay) : null

  return (
    <React.Fragment>
      <Flex variant="layout.row">
        <FormSelect label="Demo: Day" name="demoDay">
          <option value="">Select a Day</option>
          {daysWithDemos.map(day => (
            <option value={day} key={day}>
              {startCase(day)}
            </option>
          ))}
        </FormSelect>
        <FormSelect label="Time" name="demoTime" disabled={demoTimes === null}>
          <option value="">Select a Time</option>
          {demoTimes
            ? demoTimes.map(time => {
                const { title } = getClassData(demoDay, time)
                return (
                  <option value={time} key={time}>
                    {displayTime(time)} - {title}
                  </option>
                )
              })
            : null}
        </FormSelect>
        <FormSelect label="Date" name="demoDate" disabled={demoDates === null}>
          <option value="">Select a Date</option>
          {demoDates
            ? demoDates.map(dateStr => (
                <option value={dateStr} key={dateStr}>
                  {dateStr}
                </option>
              ))
            : null}
        </FormSelect>
      </Flex>
    </React.Fragment>
  )
}

export default FormRequestDemo
