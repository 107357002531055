/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import { Label, Checkbox, Text } from '../components/ui'
import { useField } from 'formik'

const FormCheckbox = ({ children, ...props }) => {
  const [field, meta] = useField({ ...props, type: 'checkbox' })
  return (
    <React.Fragment>
      <Label>
        <Checkbox {...field} {...props} sx={styles.checkbox} />
        {children}
      </Label>
      {meta.touched && meta.error ? (
        <Text variant="error">{meta.error}</Text>
      ) : null}
    </React.Fragment>
  )
}

export const styles = {
  checkbox: {
    height: '100%',
  },
}

export default FormCheckbox
