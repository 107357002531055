/** @jsx jsx */
import { useState, useCallback } from 'react'
import { navigate } from 'gatsby'
import { jsx } from 'theme-ui'
import * as yup from 'yup'
import { Box, Flex, Button, Text } from '../components/ui'
import { Formik, Form, useFormikContext } from 'formik'
import FormTextInput from './FormTextInput'
import FormCheckbox from './FormCheckbox'
import FormTextArea from './FormTextArea'
import FormRequestDemo from './FormRequestDemo'
import FormikEffect from './FormikEffect'
import { submitContact } from '../api/contact'
import { teacherForClass } from '../utils/schedule'

const INITIAL_VALUES = {
  firstName: '',
  lastName: '',
  email: '',
  demoClass: true,
  demoDay: '',
  demoTime: '',
  demoDate: '',
  message: '',
}

const VALIDATION_SCHEMA = yup.object().shape({
  firstName: yup
    .string()
    .max(30, 'Must be ${max} characters or less') // eslint-disable-line no-template-curly-in-string
    .required('required'),
  lastName: yup
    .string()
    .max(30, 'Must be ${max} characters or less') // eslint-disable-line no-template-curly-in-string
    .required('required'),
  email: yup
    .string()
    .required('required')
    .email('Not a valid email address'),
  demoClass: yup.boolean(),
  demoDay: yup.string().when('demoClass', {
    is: true,
    then: yup.string().required('required'),
    otherwise: yup.string(),
  }),
  demoTime: yup.string().when('demoClass', {
    is: true,
    then: yup.string().required('required'),
    otherwise: yup.string(),
  }),
  demoDate: yup.string().when('demoClass', {
    is: true,
    then: yup.string().required('required'),
    otherwise: yup.string(),
  }),
  message: yup.string().max(5000, ({ value, max }) => {
    return `Too many characters ${value.length} / ${max}`
  }),
})

const SubmitButton = props => {
  const { isSubmitting } = useFormikContext()
  return (
    <Button type="submit" disabled={isSubmitting}>
      {isSubmitting ? 'Submitting...' : 'Submit'}
    </Button>
  )
}

const ContactForm = ({ day = '', time = '' }) => {
  const [errorMessage, setErrorMessage] = useState(null)
  const initialValues = {
    ...INITIAL_VALUES,
    demoDay: day.toLowerCase(),
    demoTime: time,
  }
  const onSubmit = (values, { setSubmitting }) => {
    const teacher = teacherForClass(values.demoDay, values.demoTime)
    const form = {
      data: values,
      teacher,
    }
    console.log('submitting', form)
    submitContact(form)
      .then(result => {
        console.log('result', result)
        setSubmitting(false)
        navigate('/first-class-info', { state: result })
      })
      .catch(error => {
        console.log(error)
        setErrorMessage(
          'There was an error submitting the form. Please try again later or email us at taichi@taichiusa.com.'
        )
        setSubmitting(false)
      })
  }

  const clearError = useCallback(() => {
    if (errorMessage) {
      setErrorMessage(null)
    }
  }, [errorMessage])

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={VALIDATION_SCHEMA}
      >
        <Form>
          <FormikEffect onChange={clearError} />
          <Flex variant="layout.row">
            <Box variant="layout.inputGroup">
              <FormTextInput label="First Name" name="firstName" type="text" />
            </Box>
            <Box variant="layout.inputGroup">
              <FormTextInput label="Last Name" name="lastName" type="text" />
            </Box>
          </Flex>
          <Box variant="layout.inputGroup">
            <FormTextInput label="Email" name="email" type="text" />
          </Box>
          <Box variant="layout.inputGroup">
            <FormCheckbox name="demoClass">
              I would like to try a Free Demo Class
            </FormCheckbox>
          </Box>
          <FormRequestDemo />
          <Box variant="layout.inputGroup">
            <FormTextArea label="Message" name="message" rows={8} />
          </Box>
          <Box variant="layout.inputGroup">
            <SubmitButton />
            {errorMessage ? <Text variant="error">{errorMessage}</Text> : null}
          </Box>
        </Form>
      </Formik>
    </Box>
  )
}

export default ContactForm
